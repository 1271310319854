/**
 * 帮助中心
 * @type {{[]}}
 */
import { isPhone } from "@/utils/tools";
import ServiceMenu from "@/components/ServiceMenu/serviceMenu.vue";
import { helpData } from "@/service/public/enum";
const HelpCenter = {
  name: "HelpCenter",
  components: {
    ServiceMenu
  },
  data() {
    return {
      helpData: helpData,
      titleVisible: true,
      isPhone: isPhone
    };
  },
  created() {},
  mounted() {
    document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
  },
  methods: {
    onTitleVisible() {
      this.titleVisible = !this.titleVisible
      if (this.titleVisible) {
        for (let i in this.helpData) {
          this.helpData[i].visible = false
        }
      }
    },
    onClose() {
      history.back();
    },
    onVisible(id) {
      this.titleVisible = false
      for (let i in this.helpData) {
        if (id === this.helpData[i].id) {
          this.helpData[i].visible = !this.helpData[i].visible;
        } else {
          this.helpData[i].visible = false
        }
      }
    }
  }
};
export default HelpCenter;
