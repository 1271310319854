<template>
  <div class="layout-service">

    <!--s: Service Menu-->
    <ServiceMenu v-if="!isPhone()" />
    <!--e: Service Menu-->

    <div class="layout-service-content">
      <div class="service-box">
        <h1 class="title" v-if="!isPhone()"></h1>
        <h1 class="title" v-if="isPhone()">
          {{ $t("footer.helpCentre") }}
          <a href="javascript:" class="close" @click="onClose"></a>
        </h1>
        <div class="navigate-box">
          <span class="navigate-title" @click="onTitleVisible()">{{
            $t("footer.help1")
          }}</span>
          <div class="navigate-text" v-if="titleVisible">
            {{ $t("footer.help2") }}<br /><br />
            <p>{{ $t("footer.help3") }}</p>
            {{ $t("footer.help4") }}<br /><br />
            <p>{{ $t("footer.help5") }}</p>
            {{ $t("footer.help6") }}<br /><br />
            <p>{{ $t("footer.help7") }}</p>
            {{ $t("footer.help8") }}
          </div>
        </div>
        <dl :key="key" v-for="(help, key) in helpData">
          <dt @click="onVisible(help.id)">{{ help.title }}</dt>
          <dd v-if="help.visible" v-html="help.text"></dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
import HelpCenter from "./helpCenter";
export default HelpCenter;
</script>
<style lang="less">
@import "helpCenter";
</style>
